import * as React from "react"

import {graphql, Link, useStaticQuery} from 'gatsby'
import Layout from '../../components/layout/layout'
import parse from 'html-react-parser'
import Mediapost from "../../components/mediapost/mediapost"
import Adsense from "../../components/adsense/adsense";


const NewsDetailPage = ({pageContext}) => {
    const {post} = pageContext
    var pageTitle = ''
    if (post) {
        pageTitle = `Agile Hobby: ${post.title_en}`
    }

    return (
        <Layout pageTitle={pageTitle}>
                                    <Adsense client="ca-pub-6505108883414662" slot="2219379165" format="auto"/>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 pt-4">
                        {post ? (
                            <>
                                <h1 className="mb-3">News 特別報導</h1>

                                <h2 className="mb-3 orangeTitle"><Link
                                    to={`/featured/${post.slug}/`}>{post.title_en}</Link></h2>

                                {(new Date(post.creation_date_timestamp * 1000)).toLocaleDateString()}
                                <hr/>

                                {parse(post.content_en)}

                            </>
                        ) : null}


                    </div>
                </div>

            </div>

        </Layout>

    )
}

export default NewsDetailPage
